<template>
  <div class="wrapper blue-circles">
    <AuthHeader />
    <main class="page">
      <div class="container-1440">
        <div class="contacts">
          <h1 class="contacts__title footer-links-title">Контакты</h1>
          <div class="contacts__flex">
            <div class="contacts__flex-info">
              <a :href="contacts.address_url">{{ contacts.address }}</a>
              <a :href="email_url">{{ contacts.email }}</a>
              <a :href="phone_url(contacts.phone_1)">{{ contacts.phone_1 }}</a>
              <a :href="phone_url(contacts.phone_2)">{{ contacts.phone_2 }}</a>
            </div>
            <div class="contacts__flex-map">
              <iframe
                :src="contacts.map_url"
                width="581"
                height="426"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { API } from "../helpers/api";
import AuthHeader from "../components/headers/Auth.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Contacts",
  components: {
    AuthHeader,
    Footer,
  },
  data() {
    return {
      contacts: {
        address: "",
        address_url: "",
        email: "",
        phone_1: "",
        phone_2: "",
        map_url: "",
      },
    };
  },
  computed: {
    email_url() {
      return `mailto:${this.contacts.email}`;
    },
    phone_url() {
      return (phone) => `tel:${phone}`;
    },
  },
  async mounted() {
    try {
      const response = await API.send("getContacts", {}, "GET");

      if (response.status !== 0) {
        console.error(response);
        alert(response.user_message);
        return;
      }

      this.contacts = response.contacts;
    } catch (e) {
      console.error(e);
      alert("Неизвестная ошибка. Попробуйте позже.");
    }
  },
};
</script>

<style>
</style>